.DayPicker-wrapper {
  display: flex;
  flex-direction: column-reverse;
}


.DayPicker-Day {
  padding: 0.25rem 0.1rem !important;

}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: #7159c1 !important;
}

.DayPicker-Weekday {
  max-width: 34px;
}

.DayPicker-Footer {
  display: flex;
  align-items: center;
  justify-content: center;
}