ul[role="tablist"] {
  display: flex;
  flex: 1;
  flex-flow: row wrap;
  border-bottom: 1px dashed lightgray;
  position: relative;
}

ul[role="tablist"] li[aria-selected="true"]::after {
  content: "";
  display: flex;
  border-bottom: 2px solid #7159C1;
  transform: translateY(0.7rem);
}


ul[role="tablist"] li span {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
